// Explanation on web-vitals abbreviations https://web.dev/vitals/
// NB: this script is client-only
import { onINP, onLCP, onFCP, onTTFB, onCLS } from 'web-vitals';
import { Logger } from './logger';
import type { INPMetric, LCPMetric, FCPMetric, TTFBMetric, CLSMetric } from 'web-vitals';

type Metric = INPMetric | LCPMetric | FCPMetric | TTFBMetric | CLSMetric;

export function sendBrowserMetrics(context: any) {
    const watchDog = new Set();

    const namesMap = {
        INP: 'interaction-to-next-paint',
        LCP: 'largest-contentful-paint',
        FCP: 'first-contentful-paint',
        TTFB: 'time-to-first-byte',
        CLS: 'cumulative-layout-shift',
    };

    const report = (metric: Metric) => {
        const readableName = namesMap[metric.name];
        if (!readableName) {
            Logger.error(`Unknown metric name: ${metric.name}`);
            return;
        }

        const payload = {
            latency: metric.value,
            url: window.location.href,
            bag: [] as Record<string, unknown>[],
        };

        switch (metric.name) {
            case 'INP':
                payload.bag = metric.entries.map((entry) => ({
                    eventName: entry.name,
                    targetClasses: (entry.target as HTMLElement)?.className,
                }));
                break;
            case 'LCP':
                payload.bag = metric.entries.map((entry) => ({
                    loadTime: entry.loadTime,
                    renderTime: entry.renderTime,
                    size: entry.size,
                    url: entry.url,
                    targetClasses: entry.element?.className,
                }));
                break;
            case 'FCP':
                break;
            case 'TTFB':
                payload.bag = metric.entries.map((entry) => ({
                    size: entry.decodedBodySize,
                    domComplete: entry.domComplete,
                    domInteractive: entry.domInteractive,
                    download: entry.responseEnd - entry.responseStart,
                    status: entry.responseStatus,
                }));
                break;
            case 'CLS':
                payload.bag = metric.entries.map((entry) => ({
                    startTime: entry.startTime,
                    value: entry.value,
                    shifts: entry.sources.map((source) => (source.node as HTMLElement)?.className),
                }));
                break;
            default:
                metric satisfies never;
        }

        const payloadSerialized = JSON.stringify(payload);
        const opts = { type: 'application/json' };
        const blob = new Blob([payloadSerialized], opts);

        const sendBeacon = navigator.sendBeacon.bind(navigator);

        const endpoint = `${context.public.env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_SERVICE_API_CLIENT}/svc/v1/metrics/latency/${readableName}`;

        sendBeacon(endpoint, blob);
    };

    const reportOnce = (metric: Metric) => {
        if (!watchDog.has(metric.name)) {
            watchDog.add(metric.name);
        } else {
            return;
        }

        report(metric);
    };

    try {
        onINP(report);
        onLCP(reportOnce);
        onFCP(reportOnce);
        onTTFB(reportOnce);
        onCLS(report);
    } catch (error) {
        Logger.error({ error });
    }
}
